/* @import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap&font-display=swap"); */

/* .montserrat-font-title {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-display: swap;
  font-style: normal;
} */

@font-face {
  font-family: Nexa;
  src: local("Nexa"), url("./assets/fonts/Nexa-Regular.otf");
  format: ("otf");
  font-display: swap;
  font-weight: 700;
  font-style: normal;
  descent-override: 18%;
}

@font-face {
  font-family: Nexa;
  src: local("Nexa"), url("./assets/fonts/Nexa-Bold.otf");
  format: ("otf");
  font-display: swap;
  font-weight: 900;
  font-style: normal;
  descent-override: 18%;
}

.swal2-popup {
  font-family: Nexa;
}
